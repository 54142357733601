import React, {useState} from 'react'
import * as Bs from "react-bootstrap"
import styled from "styled-components"
import firebase from 'gatsby-plugin-firebase'
import {navigate} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
`

const Input = styled.input`
  display: block;
  padding: 14px;
  font-size: 1rem;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: none;
  height: 60px;
  width: 100%;
  &:focus, &:active {
    border: 1px solid rebeccapurple;
  }
`



function Hero(props) {

  const [formValues, setFormValues] = useState({
    name: '',
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    message: '',
    error: null,
  })


  async function addToLeadsEmailsDB() {
    let theSubject = `${props.content.source} - ${formValues.firstName} ${formValues.lastName} (Nouveau Client) `
    let theMessage = `${props.content.field1}: ${formValues.firstName} <br>${props.content.field2}: ${formValues.lastName}<br>${props.content.field3}: ${formValues.telephone}<br>${props.content.field4}: ${formValues.email}<br>${props.content.field5}: ${formValues.message}<br>${props.content.field6}:<br>${props.content.field7}:<br>${props.content.field8}: <br>${props.content.field9}:<br>${props.content.field10}:<br>${props.content.field11}:<br>${props.content.field12}: Web` 
    await firebase.firestore().collection('leadsEmail').add({
      source: props.content.source,
      to: props.content.to,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      status: 'live',
      reference: `${formValues.firstName} - ${formValues.lastName}`,
      telephone: formValues.telephone,
      email: formValues.email,
      message: {
        subject: theSubject,
        html: theMessage,
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
  });
    console.log('it was added to leadsEmail')
  }

  async function addToLeadsDB() {
    await firebase.firestore().collection('leads').add({
      source: props.content.source,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      status: 'live',
      reference: `${formValues.firstName} - ${formValues.lastName}`,
      telephone: formValues.telephone,
      email: formValues.email,
      message: formValues.message,
      createdAt: moment().utc().format()
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
  });
    console.log('it worked!!')
  }
  
  const handleSubmit = async e => {
    e.preventDefault()
    addToLeadsDB()
    addToLeadsEmailsDB()
    navigate(props.defaults.data.thankYou)
    console.log(`I am sending a ${formValues.name}`)
  }

  function handleInputChange(e) {
    e.persist();
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }
 
  return (
       <Form onSubmit={handleSubmit}>
         <Bs.Row>
           <Bs.Col>
         <Input style={{width: '103%'}} name="firstName" placeholder={props.content.field1} onChange={handleInputChange} type="text"/></Bs.Col>
         <Bs.Col>
           <Input style={{width: '103%', marginLeft: -7}}name="lastName" placeholder={props.content.field2} onChange={handleInputChange} type="text"/></Bs.Col>
         </Bs.Row>
  
         <Input name="telephone" placeholder={props.content.field3} onChange={handleInputChange} type="text"/>
         <Input name="email" placeholder={props.content.field4} onChange={handleInputChange} type="text"/>
         <Bs.Form.Control className="input" name="message" placeholder={props.content.field5} onChange={handleInputChange} as="textarea" rows={3} />
         <Bs.Button variant="warning" className="btn-lg btn warning" id='buttonHover' type="submit" style={{width: '100%', marginTop: 20, height: 50, fontWeight: 700, fontFamily: 'Montserrat'}}>{props.content.button} <FontAwesomeIcon icon={faArrowRight} /></Bs.Button>
       </Form>

  )

}

export default Hero